import React, { useEffect } from "react"

export default function RedirectTheFirstSuccess() {
  useEffect(() => {
    const stripeRedirect = localStorage.stripeRedirect
    localStorage.removeItem("stripeRedirect")
    window.location.replace("/_success" + stripeRedirect)
  }, [])

  return <div />
}
